import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Image from "../../ImageQuerys/NewCarouselImages";

const NewCarousel = (props) => {
  const { className } = props;
  const { t } = useTranslation("home");
  const logos = [
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fibm.png?alt=media&token=5f754cc2-8978-415d-86ba-e6f9954e5040",
      bg: "ibmBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fubits.png?alt=media&token=e2f46745-182b-46e7-9f50-db9a3218de78",
      bg: "ubitsBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2FmeruBg.png?alt=media&token=7ca2123c-eaf3-4ff4-abab-cbde1f4a6840",
      bg: "meruBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fcrabi.png?alt=media&token=bb026aa8-7e83-4cc0-a227-26abeed8912f",
      bg: "crabiBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fflink.png?alt=media&token=02e9ac44-16e3-4a83-8b85-8e63d5e193c2",
      bg: "flinkBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fgluo.png?alt=media&token=2ccd251f-03ef-457e-a86d-93f2c8ed8672",
      bg: "gluoBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fintrare.png?alt=media&token=86ec8892-80dc-476e-a6ab-f028e176ee17",
      bg: "intrareBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fkoltin.png?alt=media&token=355b5a9f-4a5a-48c7-b030-277ef5075370",
      bg: "koltinBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fkromasol.png?alt=media&token=19daba68-aafd-4dfe-afcb-859c77f5dd6c",
      bg: "kromasolBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2FoxfordU.png?alt=media&token=baf99423-4ee5-4c31-b080-a76a2f11913c",
      bg: "oxfordBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fvaliot.png?alt=media&token=2a97596e-05ba-4647-860b-87696ae006b2",
      bg: "valiotBg.jpg",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FwhiteLogos%2Fyalo.png?alt=media&token=6ccea3e1-7d49-4f58-8089-6d87af8a851b",
      bg: "yaloBg.jpg",
    },
    /*{
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F28b95914-3ab8-4488-ac94-e37a54880713.png?alt=media&token=95cee8d6-1003-4e48-8b89-7694039f8e14",
      bg: bg13,
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fxertica.png?alt=media&token=b19da879-c70f-4185-94ec-a5547e7d3af4",
      bg: bg14,
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2FcOMeZdKYEyUNvrqAIDrRAzIcRDP2%2F1614035765820?alt=media&token=2e571571-cde2-42be-bc0d-ea4879feb84c",
      bg: bg15,
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fcrabi.png?alt=media&token=45564265-362d-4133-94fc-9bab9aba4976",
      bg: bg16,
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F3483c616-eb0d-4fc9-8d85-22dd71a9bcab.png?alt=media&token=78a16403-37bd-4007-8915-91da4b48ff4d",
      bg: bg17,
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2Fc2b6828c-2e6a-49a9-9068-c6967c74018f.png?alt=media&token=af9636a2-5318-4628-bad9-28fbf5276a99",
      bg: bg18,
    },
     {
      bg: bg20,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fibm.png?alt=media&token=3c178836-1089-49dd-9b91-cc8c690da62e",
    },
    {
      bg: bg19,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F371b6d07-c0b5-4bdf-948b-78f3a6f417d0.png?alt=media&token=4c6af6d4-2684-4509-8e9f-5de5761a9100",
    },
    {
      bg: bg18,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F399f4596-e6c9-42b5-a9ab-cd57d1899278.png?alt=media&token=348a8f2a-b478-460f-aac0-f14cd6823dfc",
    },
    {
      bg: bg17,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F39c9ccc8-7860-45e3-9c1b-baf313057196.png?alt=media&token=f777d1a4-e505-499e-92e3-2355001262e2",
    },
    {
      bg: bg16,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F55451491-53ce-4624-9851-12302085e730.png?alt=media&token=3538e6da-cf19-430f-a1ef-09574258741f",
    },
    {
      bg: bg15,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F5db28f65-c31b-4674-af1e-3998aba46347.png?alt=media&token=21ff9176-b602-4e13-b288-4ff6a57bc4de",
    },
    {
      bg: bg14,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fnone.png?alt=media&token=a01e5c38-020a-4839-a36b-5288354a659a",
    },
    {
      bg: bg13,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F5f7afe4d-5e96-4c55-8023-ccf9aadb9e9d.png?alt=media&token=26603289-af37-4a54-a86c-02ebc61a1476",
    },
    {
      bg: bg12,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F61c7c21a-973f-4c80-8084-09623d84c45b.png?alt=media&token=9a8e747e-eaa8-45b0-abea-f148691062d6",
    },
    {
      bg: bg11,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F6d124277-8bfb-4974-a652-4503b88e0470.png?alt=media&token=6ad10450-65f7-46ce-8444-fbdba4e58b15",
    },
    {
      bg: bg10,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Facevedo.png?alt=media&token=55ea5db4-d3a6-454a-9c56-7945e605692e",
    },
    {
      bg: bg9,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F7154b19b-1ffe-459a-8e56-dad9cf0e4d7e.png?alt=media&token=ebb13af3-4df4-4a3b-8f7c-e83936c2f0ea",
    },
    {
      bg: bg8,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F768b306d-f007-4f72-b62d-e1d9f7d03f8b.png?alt=media&token=29601f70-b7f9-4519-b19b-a04bde53ed1e",
    },
    {
      bg: bg7,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F889de803-529e-40b5-9da8-f05e8b3bef26.png?alt=media&token=4d35f077-4e1d-4d8f-978b-1f10402981c0",
    },
    {
      bg: bg6,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2Fa9DYLU14trVqrMHe3PAtnXBjq1Z2%2F1603234077648?alt=media&token=9e2e338a-4b87-4f77-b09e-9729696b8304",
    },
    {
      bg: bg5,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fmonific.png?alt=media&token=7a6d6ca0-a4be-452a-882a-b955e5e033b4",
    },
    {
      bg: bg4,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F93bc544a-09f4-420e-87a4-21195ae92118.png?alt=media&token=06aba8a6-21fe-45b4-b2c4-edc916c42003",
    },
    {
      bg: bg3,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F978a6180-2a21-4f27-96e2-3f72eba31fb8.png?alt=media&token=ac6e35fc-3847-4a22-8016-f4b042e5e0a0",
    },
    {
      bg: bg2,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2F99d3d1ba-82e2-41ff-b673-92be1584b392.png?alt=media&token=828a6e08-98f0-4090-a321-6dec961c301b",
    },
    {
      bg: bg1,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/company_logos%2FNdd5TuF1oxdwh6u5dBIPKdwsf9C3%2F1626377922568?alt=media&token=18649b10-68a6-4a71-a71d-b95f157e32b6",
    },
    {
      bg: bg0,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fdrop.png?alt=media&token=55566d3f-9299-4093-ad48-8816b6e3dc82",
    },
    {
      bg: bg5,
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2Ftransparent%2Fjusto.png?alt=media&token=015a9595-2f27-4544-a5dd-42c6659c6796",
    },*/
  ];

  return (
    <section className={className}>
      <h2>{t("carouselLogosTitle")}</h2>
      <div className="container">
        <div className="first">
          {logos.map((l) => (
            <Image className="logo" imageName={l.bg}>
              <img className="img" src={l.img} alt="" />
            </Image>
          ))}
        </div>
        <div className="second">
          {logos.map((l) => (
            <Image className="logo" imageName={l.bg}>
              <img className="img" src={l.img} alt="" />
            </Image>
          ))}
        </div>
      </div>
    </section>
  );
};

export default styled(NewCarousel)`
  padding: 10% 0;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 8rem;
  align-content: center;
  height: fit-content;
  @media (max-width: 600px) {
    grid-row-gap: 4rem;
  }

  h2 {
    width: 60%;
    margin: auto;
    @media (max-width: 1024px) {
      height: fit-content;
      width: 75%;
    }
    @media (max-width: 768px) {
      height: fit-content;
      width: 90%;
    }
  }
  & > * {
    box-sizing: border-box;
  }
  .container {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    max-width: 6250px;
    height: 400px;
    @media (max-width: 768px) {
      max-width: 3668px;
    }

    #bgImg::before,
    #bgImg::after {
      border-radius: 1rem;
    }

    /* Breakpoints
    1024 
    768
    500
    */

    .first,
    .second {
      width: fit-content;
      position: absolute;
      height: 100%;
      top: 0;
      max-width: 6250px;
      display: grid;
      grid-template-columns: repeat(12, max-content);
      align-items: center;
      justify-content: center;
      grid-column-gap: 50px;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        grid-column-gap: 25px;
      }
    }

    .first {
      left: 0;
      animation: firstPart 120s linear infinite;
      @media (max-width: 1024px) {
        animation: firstPart1024 100s linear infinite;
      }
      @media (max-width: 768px) {
        animation: firstPart768 100s linear infinite;
      }
    }

    .second {
      left: 4200px;
      animation: secondPart 120s linear infinite;
      @media (max-width: 1024px) {
        left: -3700px;
        animation: secondPart1024 100s linear infinite;
      }
      @media (max-width: 768px) {
        left: -2700px;
        animation: secondPart768 100s linear infinite;
      }
    }
  }

  .logo {
    width: 300px;
    height: 400px;
    border-radius: 2rem;
    @media (max-width: 1024px) {
      width: 250px;
      height: 350px;
    }
    @media (max-width: 768px) {
      width: 200px;
      height: 300px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    .bg {
      position: absolute;
      z-index: -1;
      @media (max-width: 768px) {
        width: 80px;
      }
    }
    .img {
      object-fit: contain;
      width: 80%;
      z-index: 8;
    }
  }
  .logo::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    opacity: .3;
    border-radius: 1rem;
  }

  @keyframes firstPart {
    0% {
      left: 0;
    }
    100% {
      left: -4200px;
    }
  }
  @keyframes secondPart {
    0% {
      left: 4200px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes firstPart1024 {
    0% {
      left: 0;
    }
    100% {
      left: -3700px;
    }
  }
  @keyframes secondPart1024 {
    0% {
      left: 3700px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes firstPart768 {
    0% {
      left: 0;
    }
    100% {
      left: -2700px;
    }
  }
  @keyframes secondPart768 {
    0% {
      left: 2700px;
    }
    100% {
      left: 0;
    }
  }
`;
