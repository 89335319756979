import React from "react";
import styled from "styled-components";
import { getFlex, palette } from "../../styles/styles";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Percentages = (props) => {

  const { className } = props;
  const { t } = useTranslation(["home"]);

  const data = [
    { title: "70%", desc: "percentageFirstDesc" },
    { title: "3x", desc: "percentageSecondDesc" },
    { title: "90%", desc: "percentageThirdDesc" },
  ];

  return (
    <section className={className}>
      {data.map((item, index) => (
        <div className="item" id={`index${index}`}>
          <h2>{item.title}</h2>
          <p>{t(item.desc)}</p>
        </div>
      ))}
    </section>
  );
};

export default styled(Percentages)`
  padding: 0 16%;
  min-height: fit-content;
  @media(max-height: 899px){
    height: 22vh;
  }
  @media(min-height: 900px){
    height: 22rem;
  }
  background-color: ${palette.greenLight};
  color: ${palette.whiteWhite};
  ${getFlex("row", "sb", "ct")};
  column-gap: 4rem;
  @media(max-width: 600px){
    padding: 5% 10%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .item {
    // ${getFlex("column", "ct", "ct")};
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    height: 100%;
    h2 {
      font-size: 7.2rem;
      color: ${palette.whiteWhite};
      align-self: flex-end;
    }
    p {
      font-size: 2rem;
      text-align: center;
      line-height: 2.4rem;
      width: 90%;
      align-self: flex-start;
    }
  }
  #index2 {
    @media(max-width: 600px){
      grid-column: 1/-1;
    }
  }
`;
